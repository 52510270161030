import { connect } from 'react-redux';
import Component from './component';

import {
    createSegment,
    updateSegment,
    deleteSegment,
} from 'Redux/modules/admin/userSegments/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            createSegment: dispatch(createSegment),
            updateSegment: dispatch(updateSegment),
            deleteSegment: dispatch(deleteSegment),
        },
    })
)(Component);
