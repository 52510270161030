import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_CHAT_MASS_MESSAGING, ADMIN_USER_SEGMENTS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import Spinner from 'Components/layout/Spinner';
import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import UsersSegmentsForm from 'Components/admin/users/UsersSegmentsForm';

export default class AdminUsersSegmentsManage extends Component {
    static propTypes = {
        actions: PropTypes.shape({
            singleSegment: PropTypes.func.isRequired,
        }).isRequired,

        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        singleSegment: PropTypes.func.isRequired,
        segment: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }),
        }),
    };
    static defaultProps = {};

    componentDidMount = () => {
        const { segment, actions, match } = this.props;
        const { params } = match;

        if (!segment) {
            actions.singleSegment({ id: params.id });
        }
    }

    render() {
        const { location, history, segment } = this.props;

        return (
            <StyledComponent
                className="page-user-segments-manage"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_CHAT_MASS_MESSAGING.path),
                        label: 'Masowa wysyłka wiadomości',
                    },{
                        to: withVariables(ADMIN_USER_SEGMENTS_MANAGE.path),
                        label: 'Edytuj segment użytkowników',
                    }]}
                >
                    <ViewHeader
                        title="Edytuj segment użytkowników"
                    />
                    {!segment || segment.isLoading && <Spinner />}
                    {segment && segment.isLoaded && (
                        <UsersSegmentsForm
                            location={location}
                            history={history}
                            segment={segment.data}
                        />
                    )}
                </PageContent>
            </StyledComponent>
        );
    }
}