import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseToQueryString } from 'Utils/querystring';
import { withVariables } from 'Utils/string';

import {
    ADMIN_CHAT_MASS_MESSAGING,
    ADMIN_USERS,
} from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class AdminUsersSegmentsForm extends Component {
    static propTypes = {
        actions: PropTypes.shape({
            createSegment: PropTypes.func.isRequired,
            updateSegment: PropTypes.func.isRequired,
            deleteSegment: PropTypes.func.isRequired,
        }).isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        segment: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    };
    static defaultProps = {
        segment: null,
    };

    state = {
        globalError: null,
        errors: {},
        isPending: false,
        formState: {
            name: this.props.segment && this.props.segment.name || null,  //eslint-disable-line react/destructuring-assignment
        },
    };

    onStateChange = (name, value) => this.setState(prevState => ({
        formState: {
            ...prevState.formState,
            [name]: value,
        },
    }))

    onSubmit = data => {
        const { actions, history, segment } = this.props;
        let action = null;

        if (segment) {
            action = actions.updateSegment;
            data = { id: segment.id, ...data };
        } else {
            action = actions.createSegment;
        }

        return new Promise((resolve, reject) => {
            return action({ ...data })
                .then(() => resolve(history.push(parseToQueryString(ADMIN_CHAT_MASS_MESSAGING.path, { tab: 'segments' }))))
                .catch(error => reject(error));
        });
    }

    deleteSegment = () => {
        const { segment, history, actions } = this.props;

        return actions.deleteSegment({ id: segment.id })
            .then(res => {
                history.push(parseToQueryString(ADMIN_CHAT_MASS_MESSAGING.path, { tab: 'segments' }));
            });
    }

    render() {
        const { location, history, segment, actions } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="user-segments-form"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[]}
                    controls={!segment ? undefined : [{
                        title: 'Usuń segment',
                        buttonProps: {
                            onClick: this.deleteSegment,
                            children: 'Usuń',
                        },
                    }, {
                        title: 'Dodawanie/usuwanie użytkownika',
                        buttonProps: {
                            onClick: () => history.push(withVariables(ADMIN_USERS.path, {}, { segmentId: segment.id, segmentAssigned: 1 })),
                            children: 'Otwórz',
                        },
                    }]}
                    forms={[{
                        title: 'Dane segmentu',
                        submitAction: this.onSubmit,
                        data: {
                            ...formState,
                        },
                        onStateChange: this.onStateChange,
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Nazwa',
                            required: true,
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}
